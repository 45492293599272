import * as React from "react";
import ProjectSection from "../components/ProjectSection";
import { Link } from "gatsby";
import LinkArrowSvg from "../images/link-arrow";
import ClientsSection from "../components/home/ClientSection";
import Banner from "../components/Banner";
// import KnowMoreSection from "../components/KnowMoreSection";
import Faq from "../components/Faq";
import { Seo } from "../components/seo";
import LetsConnect from "../components/LetsConnect";
import KnowAbout from "../components/KnowAbout";
import WhatWeAre from "../components/WhatWeAre";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import CommonServiceComponent from "../components/service/CommonServiceComponent";

const Questions = [
  "Can you explain the process of working with your web design company in Gurgaon?",
  "Do you provide UI UX design services for both web and mobile applications?",
  "Can you work with an existing design or redesign an existing product?",
  "How long does your UI UX Design Agency, Gurgaon, take to complete a project?",
  "What is the standard budget range for a UI UX design project?",
];

const GurgaonPage = () => {
  const banner = {
    title: `UI UX Design Agency In Gurgaon`,
    content:
      "We are your go-to UI UX Design Company in Gurgaon for high-quality design services. We mix creativity, innovation, and knowledge to create extraordinary digital experiences that convert.",
    img: ["why-octet-gurgaon.webp"],
    imageTitle: "UI UX Design Agency, Gurgaon",
    imageAlt: "Octet Design Studio- UI UX Design Company in Gurgaon",
  };
  const projectData = [
    {
      title: "Unilogix App",
      desc: "A Logistics Insights Application",
      Industry: "Logistics",
      firstAlt: "Mobile App UI UX Design to track logistics performance",
      firstTitle: "Mobile UI Design to Track Logistics",
      secondAlt: "Mobile App UI UX Design to check order details",
      secondTitle: "Mobile UI Design to View Order Details",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/logistics-insight-app-design/",
      images: ["uni-logics.webp", "uni-logics-2.webp"],
    },
    {
      title: "Ship Delight",
      desc: "An E-Commerce Logistic Website",
      Industry: "Logistics",
      firstAlt: "UI UX Design to check the real time shipment status",
      firstTitle: "UI Design to Track Shipment Status",
      secondAlt: "Dashboard UI UX Design to check NDR Status",
      secondTitle: "NDR Status's UI Screen",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Wordpress Development",
          link: "/wordpress-development/",
        },
      ],
      link: "/project/shipdelight/",
      images: ["ship-delight-web.webp", "ship-delight-web-2.webp"],
    },
  ];
  const faqData = [
    {
      para: [
        `Our process typically involves initial <a href="/ux-consulting/" title="UX Consultation Services"> consultations</a>, <a href="/user-research/" title="User Research Services">user research</a>, <a href="/rapid-prototyping/" title="Rapid Prototyping Services">prototyping</a>, feedback iterations, <a href="/usability-testing/" title="Usability Testing Services">usability testing</a> and final implementation. However, this process might differ based on project requirements and various other factors.`,
      ],
    },
    {
      para: [
        `Our UI UX design agency in Gurgaon specializes in designing user interfaces and experiences for web and mobile applications to ensure a consistent and seamless user journey across platforms.`,
      ],
    },
    {
      para: [
        `Being the top website designing agency in Gurgaon, we collaborate on redesigning existing products or improving your UI UX to enhance user experience and achieve your objectives.`,
      ],
    },
    {
      para: [
        `The timeline for a project varies depending on its complexity and scope. Being one of the best UI UX design companies in Gurgaon, we work closely with our clients to establish realistic timelines during the project planning phase.`,
      ],
    },
    {
      para: [
        `Our project budgets vary widely depending on project scope and complexity. We provide customized quotes after discussing your specific requirements and goals.`,
      ],
    },
  ];
  const lastLength = projectData;
  const info = {
    title: "Collaborate with our Mobile App Design Agency, Gurgaon",
    link: "/contact-us/",
    text: "Contact Us",
  };
  const whatWeAreData = {
    title: "Drive Growth with our <span class='h1-span'>Web Design Company</span> in Gurgaon",
    des: "Being one of the best website designing company in Gurgaon, we offer an extensive range of services to optimize and improve your digital experiences.",
    items: [
      {
        title: "User Research",
        content:
          "We utilize various techniques to collect data and gain insights that help us create products that meet our customers' needs. Our UI UX design company in Gurgaon aims to ensure that your product exceeds your expectations.",
        link: "/user-research/",
        linkText: `To achieve long-term benefits, our <a href="/user-research/" title="User Research Services">user research agency</a> streamlines your resource-intensive processes.`
      },
      {
        title: "UI UX Designing",
        content:
          "Being the best website designing company in Gurgaon, we systematically create interactive, aesthetically pleasing interfaces that guarantee a seamless and straightforward user experience.",
        link: "/ui-ux-designing/",
        linkText: `For better performance, our <a href="/ui-ux-designing/" title="UI UX design Services">UI UX Design Agency</a> in Gurgaon creates personalized solutions for your company.`
      },
      {
        title: "Usability Testing",
        content:
          "Our web design company in Gurgaon makes your products user-friendly and accessible by using various tools and techniques. This allows us to identify areas for improvement and ensure consistency across all our products.",
        link: "/usability-testing/",
        linkText: `To create an interface that is easy to use, our <a href="/usability-testing/" title="Usability Testing Services">Usability Testing Company</a> monitors how you use it and spots problems.`
      },
      {
        title: "UI Development",
        content:
          "We aim to deliver exceptional user experiences and ensure that every design is meticulously transformed into a template with captivating visual components fully integrated into the Java framework.",
        link: "/ui-development/",
        linkText: `Our <a href="/ui-development/" title="UI Development Company">UI Development Company</a> uses its proficiency in multiple programming languages to create functional user interfaces.`
      },
    ],
    mainLink: {
      link: "/services/",
      text: "View All UI UX Services",
    },
  };
  const commonContent = {
    title: "Experience Innovation with our Website Designing Company, Gurgaon",
    content: [
      {
        title: "Customized User Interface",
        text: "We create user interfaces that connect with your target audience, guaranteeing an exceptional and unforgettable experience.",
      },
      {
        title: "Timeline Efficiency",
        text: "Our interface design agency optimizes the design approach to meet your needs, cutting expenses and time without sacrificing quality.",
      },
      {
        title: "Scalable Designs",
        text: "Our UI UX design services are flexible enough to adjust to updates and changing technology environments quickly.",
      },
      {
        title: "Easily Accessible",
        text: "Our mobile app design company in Gurgaon ensures that all your products are accessible and usable across devices and platforms.",
      }
    ],
  };
  const commonContent1 = {
    title: "Creating Pixel-Perfect <span class='h1-span'>Designs with our</span> UI UX Process",
    para: ['Our broad experience has increased the results of companies that employ our comprehensive UI UX design company in Gurgaon to meet quality standards and deliver on time.'],
    content: [
      {
        title: "1. Briefing",
        text: "To establish the nature of our collaboration, our top UI UX design agency in Gurgaon carries out design audits, competitive analyses, discussions, and usability testing that clarify product and business difficulties.",
      },
      {
        title: "2. Defining",
        text: "Our website designing company in Gurgaon then determines your target market by utilizing various techniques to fully grasp their issues. As a team, we define the project, set deadlines, write down objectives, and decide on metrics to help you succeed.",
      },
      {
        title: "3. Strategizing",
        text: "Once we set an objective, the team at our UI UX design agency in Gurgaon strategizes the user experience flow, develops guidelines, and structures information architecture to organize your website's content and effectively achieve its purpose.",
      },
      {
        title: "4. Sketching",
        text: "Our UX studio formulate an effective visual hierarchy for your app or website to facilitate comprehension. We aim to provide you with a visually appealing, user-friendly interface that enhances the user experience and maximizes your product's potential.",
      },
      {
        title: "5. Designing & Testing",
        text: "After finalizing the layout and designs, we develop a prototype and test the end product's functionality. This enables our team to evaluate the final product and ensure it meets the required standards and specifications.",
      }
    ],
  }
  const faqDes = `You must have other questions about our UI UX design company in Gurgaon. We have compiled a comprehensive list of questions to help you get answers quickly. However, if you still have any questions, please <a href="/contact-us/" title="Contact Us"> contact us</a>.`;
  const knowAboutDes = [
    " Octet is a prominent UI UX design agency in Gurgaon. We transform SaaS, Enterprise, and B2B products through a wise, proactive, and proficient approach. Our flexible, lean, and agile UI UX design services empower businesses to embark on a revolutionary journey and realize their full potential. We deliver results beyond expectations with unmatched expertise and the latest industry best practices.",
  ];

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "Can you explain the process of working with your web design company in Gurgaon?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our process typically involves initial consultations, user research, design prototyping, feedback iterations, usability testing, and final design implementation. However, this process might differ based on project requirements and other factors."
                }
              },{
                "@type": "Question",
                "name": "Do you provide UI UX design services for both web and mobile applications?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our UI UX design agency in Gurgaon specializes in designing user interfaces and experiences for web and mobile applications to ensure a consistent and seamless user journey across platforms."
                }
              },{
                "@type": "Question",
                "name": "Can you work with an existing design or redesign an existing product?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Being the top website designing agency in Gurgaon, we collaborate on redesigning existing products or improving your UI UX to enhance user experience and achieve your objectives."
                }
              },{
                "@type": "Question",
                "name": "How long does your UI UX Design Agency, Gurgaon, take to complete a project?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The timeline for a project varies depending on its complexity and scope. Being one of the best UI UX design companies in Gurgaon, we work closely with our clients to establish realistic timelines during the project planning phase."
                }
              },{
                "@type": "Question",
                "name": "What is the standard budget range for a UI UX design project?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our project budgets vary widely depending on project scope and complexity. We provide customized quotes after discussing your specific requirements and goals."
                }
              }]
            }
           `}
        </script>
      </Helmet>
      <Layout>
        <Banner
          content={banner}
          page={"ahmedabad-home"}
          location="WeWork BlueOne Square, 246, Phase IV, Udyog Vihar, Gurgaon - 122016"
        />
        <WhatWeAre data={whatWeAreData} />
        <KnowAbout title={`Choose our UI UX Design Company in Gurgaon`} des={knowAboutDes} imageAlt={"Logo of UI UX Design Agency in Gurgaon"} imageTitle={"UI UX Design Agency in Gurgaon"} />
        <CommonServiceComponent data={commonContent} mode={"grey"} nopara />
        <CommonServiceComponent data={commonContent1} mode={"light"} list='single' nopara />
        <section className="project-section lg:py-[200px] md:pb-[125px] md:pt-[140px] pt-[100px] pb-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
          <div className="container">
            <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
              Our Portfolio of UX UI Design
            </h2>
          </div>
          {projectData.map((data, index) => (
            <div key={index}>
              <ProjectSection num={index} lastLength={lastLength} data={data} />
            </div>
          ))}
          <div className="container lg:mt-[-100px] md:mt-[-35px] mt-[-40px]">
            <Link
              title="UI UX Design Portfolio"
              to="/projects/"
              className="animated-link font-medium font-heading text-[20px] md:text-[22px] lg:text-[30px] md:leading-[1.33] leading-[normal] tracking-[.05em] flex items-center gap-[32px] md:gap-[38px]"
            >
              <p className="max-w-[262px] md:max-w-none w-auto font-heading">
                See more work by our UI UX design agency{" "}
              </p>
              <span>
                <LinkArrowSvg />
              </span>
            </Link>
          </div>
        </section>
        <ClientsSection title={'Trusted by Clients Worldwide'} />
        <LetsConnect data={info} />
        <Faq section Questions={Questions} faqData={faqData} des={faqDes} />
        {/* <KnowMoreSection nin="Contact Us" Include="services" /> */}
      </Layout>
    </>
  );
};

export default GurgaonPage;

export const Head = () => (
  <Seo
    title="UI UX Design Agency In Gurgaon - Octet"
    description="Discover top-notch UI UX design services with our leading UI UX Design Agency in Gurgaon. Elevate user experiences with our expert UI UX design solutions."
  />
);
